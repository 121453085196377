@import 'mixin.scss';

.selectionWineNumber{
    width: 58%;
    .formTitle{
        color: #510000;
        font-size: 38px;
        background-color: rgba(255, 225, 187, 0.7411764706);
        margin: 10px;
        backdrop-filter: blur(10px);
        box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.5), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.5);
        border-radius: 0.3rem;
        padding: 0.75rem;
    }
    .formSelectionWine{
        margin: 1rem;
        padding: 1rem;
        display: flex;
            flex-wrap: wrap;
            gap: 3rem;
            flex-direction: row;
            justify-content: space-around;
        background-color: rgba(235, 225, 197, 0.42);;
        border-radius: 5px;
        .inputNumberWine{
            width: 7.5rem;
            display: flex;
            border: 2px solid #27050559;
            border-radius: 5px;
            padding: 5px;
            margin-top: 5px;
            background-color: aliceblue;
            flex-direction: row;
            .updown{
                width: 1rem;
                height: 1rem;
            }
            .numberWine{
                border:none;
                background-color: aliceblue;
            }
            .wineNumberControl{
                display: flex;
                width: auto;
                background-color: aliceblue;
                gap: 1rem;
            }
        }
        .infoAchat{
            display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1rem;
                flex-wrap: wrap;
        }
    }
    .buttonContinue{
        background-color: rgba(42, 185, 58, 0.89);
        font-size: large;
        font-style: italic;
        font-weight: lighter;
        display: block;
    width: 6rem;
    height: 3rem;
    box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.64), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.81);
    border-radius: 8px;
    border-color: transparent;
    color: white;
    font-size: medium;
}}


.formClient{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .formTitle{
        color: #510000;
        font-size: 38px;
        background-color: #ffe1bbbd;    
        padding: 10px;
        border-radius: 10px;
        @include respond (small){
            width: 30rem;
        }
    }
    .informationsClient{
        margin: 10px;
        padding: 10px;
    width: 30rem;
    height: 20rem;
    display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-around;
        @include respond (small) {
            width: auto;
            overflow: scroll;
        }
    }
    .buttonToStripe{
        cursor: pointer;
        display: block;
        height: 40px;
        width: 180px;
        font-style: italic;
        font-weight: lighter;
        background-color: rgb(42 185 58 / 89%);
        box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 39%), -0.25rem -0.25rem 0.75rem rgb(251 251 251 / 88%);
        color: white;
        border-radius: 5px;
        border-color: transparent;
        font-size: large;
    }
}

.modal form{
    height: 500px;
    background: rgb(235 225 197 / 42%);
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.infoClientLabel{
    display: flex;
    flex-direction: column;
    width: 180px;

}

.modal .closeButton{
    cursor: pointer;
    display: block;
    background-color: #6d0707;
    width: 6rem;
    height: 3rem;
    box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.64), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.81);
    border-radius: 8px;
    border-color: transparent;
    color: white;
    font-size: medium;
}

.modal form, .formStripe{
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 2rem;
    border-radius: 1rem;
    border: 1rem solid #d3c39736;
    margin: 1rem;
    .buttonToStripe{
        cursor: pointer;
        display: block;
        height: 40px;
        width: 180px;
        font-style: italic;
        font-weight: lighter;
        background-color: rgb(42 185 58 / 89%);
        box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 39%), -0.25rem -0.25rem 0.75rem rgb(251 251 251 / 88%);
        color: white;
        border-radius: 5px;
        border-color: transparent;
        font-size: large;
    }
    .containerButtonStripe{
        display: flex;
        flex-direction: row-reverse;
    }
}

.paymentSuccess{
    display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 1rem;
    background-color: #fff9e5;
    padding: 1rem;
    border-radius: 1rem;
    border: solid #cba11deb 0.2rem;
    h3{
        font-size: x-large;
        display: flex;
            justify-content: center;
        padding-bottom: 1rem;
    }
    p{
        font-size: larger;
    }
}