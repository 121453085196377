
.modal-menu {
    
    right: 2vmin;
    top: 0.2vmin;
        @include respond (medium){
            top: 1vmin;
        }
        @include respond (small){
            right: 3.5vmin;
            top: 1.6vmin;
        }
        @include respond (smaller){
            right: 3vmin;
        top: 2vmin;
        }
        @include respond (hmedium){
            padding: 1.32vh 1.8vw;
        }
    border: 2px solid;
        border-radius: 6px;
        border-color: transparent;
    background: rgb(29 29 29 / 69%);
        width: 10rem;
    padding: 1rem;
    padding-top: 5rem;
    display: flex;
        flex-direction: column;
        align-items: flex-end;
    ul{
        display: flex;
            flex-direction: column;
            align-items: flex-end;
        font-size: x-large;
        gap: 1rem;
        list-style: none;
        li{
            a{
                color: $color-white;
                text-decoration: none;
                
                &:after {
                    background: none repeat scroll 0 0 transparent;
                    content: "";
                    display: block;
                    height: 2px;
                    background: $color-white;
                    transition: width 0.3s ease 0s, left 0.3s ease 0s;
                    width: 0;
                  }
                  &:hover:after { 
                    width: 100%; 
                    left: 0; 
                  }
            }
        }
    }
}
