@import 'variable.scss';
@import 'mixin.scss';
@import 'majority.scss';

body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
}
h3, h4, p {
    margin: 0;
    padding: 0;
}
h1, h2, h3, h4, h5 {
    font-family: 'Libre Baskerville', serif;
}
p, a{
    font-family: 'Lato';
}
html {
    scrollbar-width: none;
}
.close-modal{
    margin-top: 2rem;
    display:flex;
    justify-content: center;
    align-items: center;
    h5{
        display: flex;
            justify-content: center;
            align-items: center;
        background-color: #6d0707;
            width: 6rem;
            height: 3rem;
            box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 64%), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.81);
        border-radius: 8px;
        color: white;
        font-size: medium;       
        &:hover {
            box-shadow: inset 0.25rem 0.25rem 0.75rem rgb(42 9 9 / 51%), inset -0.25rem -0.25rem 0.75rem rgb(255 0 0 / 22%);
        }
        @include respond (medium){
            font-size: small
        }
        @include respond (small){
            font-size: smaller
        }
        
    }
    @include respond (medium){
        flex-direction: column-reverse;
    }
}

@import 'header.scss';
@import 'menu.scss';
@import 'home.scss';
@import 'wine.scss';
@import 'carrousel.scss';
@import 'formWine.scss';
@import 'history.scss';
@import 'event.scss';
@import 'contact.scss';
@import 'maps.scss';
@import 'footer.scss';
@import 'mention.scss'
