@import 'variable.scss';

.modal-wine{
  .close-modal{
    margin: 0;
    gap: 50%;
    .buttonBuy{
      background-color: rgba(42, 185, 58, 0.89);
      font-size: large;
      font-style: italic;
      font-weight: lighter;
    }
  }
}
.wine {
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    @include respond (small){
      flex-direction: column;
    }
}
.qr-code-section {
  display: none; 
}

@media (min-width: 1024px) {
  .qr-code-section {
    display: block; 
  }
}

@media (max-width: 767px) {
  .qr-code-section {
    display: none;
  }
}
#qr-code{
  width: 20rem;
  height: auto;
}
.section .modal{
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}

.info {
  padding-right: 10vw;
  padding-left: 2vw;
  padding-top: 5vh;
  font-size: larger;
  height: 60vh;
  overflow-x: hidden;
  @include respond (small){
    height: 25vh;
    padding-top: 0;
  }
    .info-row {
        display: flex;
        margin-bottom: 10px;
        @include respond (medium){
        margin-bottom: 1rem;
        flex-direction: column;
        font-size: smaller;
        }
      }
      .info-title {
        width: 9rem;
        font-weight: bold;
      }
      
      .info-value {
        flex: 1; 
      }
    h3, h4{
        color: $background-color-red;
    }
    h3{
        margin-bottom: 0.2rem;
        font-size: xx-large;
        @include respond (large){
          font-size: x-large;
        }
        @include respond (medium){
          font-size: medium;
        }
    }
    h4{
        margin-bottom: 2rem;
        font-size: larger;
        @include respond (large){
          font-size: medium;
        }
        @include respond (medium){
          font-size: small;
        }
    }
}