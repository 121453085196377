.legal-mention{
    padding: 10% 10% 0 10%;
}

#legal-mention {
    display: none;
    /* ou */
    visibility: hidden;
    opacity: 0;
  }
  
  #legal-mention.show {
    display: block;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

.mention-close {
  padding-bottom: 30rem;
}